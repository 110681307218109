import { convertAddress, loadSuggestions } from "@/util/address";
import api from "@/util/api";
import { router } from "@inertiajs/vue3";
import { notify } from "notiwind";

export default {
    namespaced: true,

    state () {
        return {
            id: null,
            products: [],
            products_count: 0,
            total_products_count: 0,
            promocode: null,
            totals: {},
            total: 0,

            loading: false,

            delivery_method: null,
            delivery_method_fields: [],
        }
    },

    getters: {
        id: (state) => state.id,
        products: (state) => state.products,
        products_count: (state) => state.products_count,
        total_products_count: (state) => state.total_products_count,
        delivery_method: (state) => state.delivery_method,
        delivery_method_fields: (state) => state.delivery_method_fields,
        promocode: (state) => state.promocode,
        totals: (state) => state.totals,
        total: (state) => state.total,

        loading: (state) => state.loading,
    },

    mutations: {
        setCart (state, cart) {
            state.id = cart?.id;
            state.products = cart?.products;
            state.products_count = cart?.products_count;
            state.promocode = cart?.promocode;
            state.delivery_method = cart?.delivery_method;
            state.delivery_method_fields = cart?.delivery_method_fields;
            state.total_products_count = cart?.total_products_count;
            state.totals = cart?.totals;
            state.total = cart?.total;
        },

        setLoading (state, loading) {
            state.loading = loading;
        }
    },

    actions: {
        add (store, product, count) {
            store.commit('setLoading', true);

            axios.post(route('market.cart.products.store'), {
                product_id: product.id,
                count: count ?? 1,
            }, {
                headers: {
                  'Accept': 'application/json', // Specify JSON as the expected response format
                },
                preserveState: true,
            }).then((response) => {
                store.commit('setCart', response.data?.cart);
                store.commit('setLoading', false);
                ym(98875133,'reachGoal','add_to_cart');
            }).catch((error) => {
                store.commit('setLoading', false);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error.response.data.message
                }, 4000)
            })
        },

        update (store, {product, count}) {
            store.commit('setLoading', true);

            axios.put(route('market.cart.products.update', {product: product}), {
                count: count ?? 1,
            }, {
                headers: {
                    'Accept': 'application/json', // Specify JSON as the expected response format
                },
                preserveState: true,
            }).then((response) => {
                store.commit('setCart', response.data?.cart);
                store.commit('setLoading', false);
            }).catch((error) => {
                store.commit('setLoading', false);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error.response.data.message
                }, 4000)
            })
        },

        remove (store, {product}) {
            store.commit('setLoading', true);
            axios.delete(route('market.cart.products.destroy', {product: product}), {
                headers: {
                    'Accept': 'application/json', // Specify JSON as the expected response format
                },
                preserveState: true,
            }).then((response) => {
                store.commit('setCart', response.data?.cart);
                store.commit('setLoading', false);
            }).catch((error) => {
                store.commit('setLoading', false);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error.response.data.message
                }, 4000)
            })
        },

        setDeliveryTariff (store, {method, tariff, address}) {
            store.commit('setLoading', true);
            loadSuggestions(address.address ?? address.formatted).then((suggestions) => {
                axios.post(route('market.cart.update'), {
                    address: {
                        name: address.address ?? address.formatted,
                        ...convertAddress(suggestions[0])
                    },
                    delivery_params: {
                        tariff,
                        method,
                        office_code: address.code ?? null,
                    }
                }, {
                    headers: {
                        'Accept': 'application/json', // Specify JSON as the expected response format
                    },
                    preserveState: true,
                }).then((response) => {
                    store.dispatch('reload')
                    // store.commit('setCart', response.data?.cart);
                    // store.commit('setLoading', false);
                }).catch((error) => {
                    store.commit('setLoading', false);
                    notify({
                        type: 'fail',
                        group: "main",
                        title: "Ошибка",
                        text: error.response.data.message
                    }, 4000)
                })
            }).catch((error) => {
                store.commit('setLoading', false);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error.response.data.message
                }, 4000)
            })
        },

        setDeliveryMethod (store, delivery_method) {
            store.commit('setLoading', true);
            axios.post(route('market.cart.update'), {delivery_method}, {
                headers: {
                    'Accept': 'application/json', // Specify JSON as the expected response format
                },
                preserveState: true,
            }).then((response) => {
                store.commit('setCart', response.data?.cart);
                store.commit('setLoading', false);
            }).catch((error) => {
                store.commit('setLoading', false);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error.response.data.message
                }, 4000)
            })
        },

        reload (store) {
            store.commit('setLoading', true);
            router.reload({
                only: ['cart', 'deliveryMethods'],
                preserveState: true,
                preserveScroll: true,
                onSuccess: (page) => {
                    store.commit('setCart', page.props?.cart);
                    store.commit('setLoading', false);
                }
            })
        }
    }
}
