import api from "@/util/api";
import { router } from "@inertiajs/vue3";
import { notify } from "notiwind";

export default {
    namespaced: true,

    state: () => ({
        order: null,
        loading: false,
        product: null,
    }),

    getters: {
        order: (state) => state.order,
        products: (state) => state.order?.products,
        product: (state) => state.product,
        loading: (state) => state.loading,
    },

    mutations: {
        setOrder: (state, order) => {
            state.order = order;
        },

        setLoading: (state, loading) => {
            state.loading = loading;
        },

        setProduct: (state, product) => {
            state.product = product;
        },

        addProduct: (state, product) => {
            state.products = [...state.products, product];
        },

        removeProduct: (state, id) => {
            state.products = state.products.filter(p => p.id != id)
        },

        updateProduct: (state, product) => {
            state.products = state.products.map(p => p.id == product.id ? product : p)
        }
    },

    actions: {
        findProduct (store, sku) {
            if (store.state.loading) {
                return null;
            }

            store.commit('setLoading', true);
            api.request().get(`/api/v1/manager/products/by-sku/${sku}`).then((response) => {
                if (response.data.success) {
                    store.commit('setLoading', false);
                    store.commit('setProduct', response.data.data);
                } else {
                    throw new Error('Ошибка.')
                }
            }).catch((error) => {
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: "Товар не найден."
                }, 4000)

                store.commit('setLoading', false);
            })
        },

        confirmProduct (store) {
            store.commit('setLoading', true);
            api.request().post(`/api/v1/manager/orders/${store.state.order.id}/products`, {
                product_id: store.state.product.id
            }).then((response) => {
                store.commit('setProduct', null);
                store.commit('setLoading', false);

                store.dispatch('refreshOrder');
            }).catch((error) => {
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: "Произошла ошибка"
                }, 4000)

                store.commit('setLoading', false);
            })
        },

        deleteProduct (store, productId) {
            store.commit('setLoading', true);
            api.request().delete(`/api/v1/manager/orders/${store.state.order.id}/products/${productId}`).then((response) => {
                store.commit('setLoading', false);

                store.dispatch('refreshOrder');
            }).catch((error) => {
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: "Произошла ошибка"
                }, 4000)

                store.commit('setLoading', false);
            })
        },

        setPromocode (store, promocode) {
            store.commit('setLoading', true);
            api.request().post(`/api/v1/manager/orders/${store.state.order.id}/promocode`, {
                _method: 'PATCH',
                promocode
            }).then((response) => {
                store.commit('setLoading', false);

                notify({
                    type: 'success',
                    group: "main",
                    title: "Успешно",
                    text: "Промокод применен"
                }, 4000)

                store.dispatch('refreshOrder');
            }).catch((error) => {
                console.error(error);
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error?.response?.data?.message || "Произошла ошибка"
                }, 4000)

                store.commit('setLoading', false);
            })
        },

        setUser (store, data) {
            store.commit('setLoading', true);

            api.request().post(`/api/v1/manager/orders/${store.state.order.id}/customer`, {
                ...data,
                _method: 'PATCH',
            }).then((response) => {
                store.commit('setLoading', false);

                notify({
                    type: 'success',
                    group: "main",
                    title: "Успешно",
                    text: "Данные обновлены"
                }, 4000)

                store.dispatch('refreshOrder');
            }).catch((error) => {
                notify({
                    type: 'fail',
                    group: "main",
                    title: "Ошибка",
                    text: error?.response?.data?.message || "Произошла ошибка"
                }, 4000)

                store.commit('setLoading', false);
            })
        },

        refreshOrder (store) {
            store.commit('setLoading', true);
            router.reload({
                preserveState: true,
                only: ['order'],
                preserveScroll: true,
                onSuccess: (page) => {
                    store.commit('setOrder', page.props?.order);
                    store.commit('setLoading', false);
                },
                onError: (error) => {
                    store.commit('setLoading', false);
                    notify({
                        type: 'fail',
                        group: "main",
                        title: "Ошибка",
                        text: error.response.data.message
                    }, 4000)
                }
            })
        }
    },
}
